<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-card>
            <v-card-title>
              Coupons
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                @change="searchCoupons()"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :mobile-breakpoint="0"
              :single-expand="true"
              :headers="datatable.headers"
              :items="datatable.desserts"
              :server-items-length="datatable.total"
              :search="search"
              :loading="datatable.isLoading"
              :options.sync="options"
              :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 20, 50, 100]
              }"
              item-key="id"
            >
              <template v-slot:item.sales_person="{ item }">
                <b-form-select
                  id="sales_person_id"
                  required
                  :options="salesPersons"
                  value-field="edara_id"
                  text-field="name"
                  @change="updateCoupon(item)"
                  v-model="item.sales_person_id"
                ></b-form-select>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<style scoped lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import ApiService from "../../../core/services/api.service";
import Vue from "vue";
import _ from "lodash";

const ignoreWarnMessage = `The .native modifier for v-on is only valid on components but it was used on <div>.`;
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function(msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

export default {
  name: "Coupons",
  data() {
    return {
      salesPersons: [],
      search: "",
      options: {},
      datatable: {
        total: 0,
        isLoading: false,
        headers: [
          { text: "Code", value: "code", width: "15%", sortable: true },
          { text: "Amount", value: "amount", width: "15%", sortable: true },
          {
            text: "Sales Person",
            value: "sales_person",
            width: "30%",
            sortable: false
          },
          {
            text: "Description",
            value: "description",
            width: "40%",
            sortable: true
          }
        ],
        desserts: []
      }
    };
  },
  components: {},
  watch: {
    options: {
      handler() {
        this.loadCoupons();
      },
      deep: true
    }
  },
  methods: {
    updateCoupon(item) {
      return ApiService.put(`coupons/${item.id}`, item)
        .then(() => {
          this.makeToast("success", "Coupon assigned to Sales Person");
        })
        .catch(() => {
          this.makeToast("danger", "Error while updating coupon");
        });
    },
    loadSalesPersons() {
      return ApiService.get("sales-persons").then(({ data }) => {
        const options = [
          {
            edara_id: null,
            name: "Select..."
          }
        ];
        this.salesPersons = options.concat(data);
      });
    },
    loadCoupons() {
      this.datatable.isLoading = true;

      let sortBy = "";
      let sortDesc = false;
      if (this.options.sortBy.length == 0) {
        sortBy = "amount";
        sortDesc = true;
      } else {
        sortBy = this.options.sortBy[0];
        sortDesc = this.options.sortDesc[0];
      }

      ApiService.get("coupons", {
        per_page: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: sortBy,
        sort: sortDesc ? "DESC" : "ASC",
        search: this.search
      })
        .then(({ data }) => {
          this.datatable.total = data.total;
          this.datatable.desserts = data.data;
        })
        .finally(() => {
          this.datatable.isLoading = false;
        });
    },
    searchCoupons() {
      this.page = 1;
      this.loadCoupons();
    },
    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: `Settings`,
        variant: variant,
        solid: true
      });
    }
  },
  created() {
    this.loadCoupons = _.debounce(this.loadCoupons, 300);
  },
  mounted() {
    this.loadCoupons();
    this.loadSalesPersons();
  }
};
</script>
