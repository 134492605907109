<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-form @submit="onSubmit">
          <b-form-group
            label-cols="9"
            label-cols-lg="6"
            label="PC Warehouse:"
            label-for="pc_warehouse_id"
          >
            <b-form-select
              id="pc_warehouse_id"
              required
              :options="warehouses"
              :state="pcWarehouseIdState"
              value-field="edara_id"
              text-field="name"
              v-model="settings.pc_warehouse_id"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            label-cols="9"
            label-cols-lg="6"
            label="Items Warehouse:"
            label-for="items_warehouse_id"
          >
            <b-form-select
              id="items_warehouse_id"
              required
              :options="warehouses"
              :state="itemsWarehouseIdState"
              value-field="edara_id"
              text-field="name"
              v-model="settings.items_warehouse_id"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            label-cols="9"
            label-cols-lg="6"
            label="Services Warehouse:"
            label-for="services_warehouse_id"
          >
            <b-form-select
              id="services_warehouse_id"
              required
              :options="warehouses"
              :state="servicesWarehouseIdState"
              value-field="edara_id"
              text-field="name"
              v-model="settings.services_warehouse_id"
            ></b-form-select>
          </b-form-group>

          <b-form-group
            label-cols="9"
            label-cols-lg="6"
            label="Edara Store Id:"
            label-for="edara_store_id"
          >
            <b-form-input
              id="edara_store_id"
              required
              :state="edaraStoreIdState"
              v-model="settings.edara_store_id"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols="9"
            label-cols-lg="6"
            label="Default Sales Person:"
            label-for="sales_person_id"
          >
            <b-form-select
              id="sales_person_id"
              required
              :options="salesPersons"
              value-field="edara_id"
              text-field="name"
              v-model="settings.sales_person_id"
            ></b-form-select>
          </b-form-group>

          <div class="row">
            <b-form-checkbox
              id="sms_notification"
              v-model="settings.sms_notification"
              name="sms_notification"
              :checked="settings.sms_notification"
            >
              Enable SMS notifications
            </b-form-checkbox>
          </div>

          <div class="row">
            <b-form-checkbox
              id="split_orders"
              v-model="settings.split_orders"
              name="split_orders"
              :checked="settings.split_orders"
            >
              Split Orders has more than PC
            </b-form-checkbox>
          </div>

          <div class="row">
            <b-form-checkbox
              id="sync_orders"
              v-model="settings.sync_orders"
              name="sync_orders"
              :checked="settings.sync_orders"
            >
              Sync Orders to Edara
            </b-form-checkbox>
          </div>

          <div class="row">
            <b-form-checkbox
              id="sync_price"
              v-model="settings.sync_price"
              name="sync_price"
              :checked="settings.sync_price"
            >
              Sync Edara Price to PCD Store
            </b-form-checkbox>
          </div>

          <div class="row">
            <b-form-checkbox
              id="sync_inventory"
              v-model="settings.sync_inventory"
              :checked="settings.sync_inventory"
              name="sync_inventory"
            >
              Sync Inventory to PCD Store
            </b-form-checkbox>
          </div>
          <div class="row">
            <div class="col-2">
              <b-button type="submit" variant="primary">Save</b-button>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import ApiService from "../../../core/services/api.service";

export default {
  name: "GeneralSettings",
  data() {
    return {
      settings: {
        sync_orders: "",
        sync_price: "",
        sms_notification: "",
        split_orders: "",
        sync_inventory: "",
        edara_store_id: "",
        pc_warehouse_id: "",
        items_warehouse_id: "",
        services_warehouse_id: "",
        sales_person_id: ""
      },
      salesPersons: []
    };
  },
  components: {},
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.updateSettings(this.settings);
    },
    updateSettings(settings) {
      ApiService.post("settings/save", settings)
        .then(({ data }) => {
          this.settings = data;
          this.makeToast("success", "Settings Saved Successfully!");
        })
        .catch(() => {
          this.makeToast("danger", "Error while saving settings");
        });
    },
    checkValidId(str) {
      let n = Math.floor(Number(str));
      return n !== Infinity && String(n) == str && n > 0;
    },
    loadSettings() {
      ApiService.get("settings").then(({ data }) => {
        this.settings = data;
      });
    },
    loadSalesPersons() {
      return ApiService.get("sales-persons").then(({ data }) => {
        const options = [
          {
            edara_id: null,
            name: "Select..."
          }
        ];
        this.salesPersons = options.concat(data);
      });
    },
    loadWarehouses() {
      return ApiService.get("warehouses").then(({ data }) => {
        this.warehouses = data;
      });
    },
    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: `Settings`,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    pcWarehouseIdState() {
      return this.checkValidId(this.settings.pc_warehouse_id);
    },
    itemsWarehouseIdState() {
      return this.checkValidId(this.settings.items_warehouse_id);
    },
    servicesWarehouseIdState() {
      return this.checkValidId(this.settings.services_warehouse_id);
    },
    edaraStoreIdState() {
      return this.checkValidId(this.settings.edara_store_id);
    }
  },
  mounted() {
    Promise.all([this.loadSalesPersons(), this.loadWarehouses()]).then(() => {
      this.loadSettings();
    });
  }
};
</script>
