<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-3">
        <b-tab title="General Settings">
          <GeneralSettings />
        </b-tab>
        <b-tab title="Coupons">
          <Coupons />
        </b-tab>
        <b-tab title="Notifications">
          <Notifications />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Coupons from "./Coupons";
import GeneralSettings from "./GeneralSettings";
import Notifications from "./Notifications";

export default {
  data() {
    return {};
  },
  components: {
    Notifications,
    Coupons,
    GeneralSettings
  },
  methods: {},
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings", route: "settings" }
    ]);
  }
};
</script>
