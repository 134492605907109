<template>
  <div>
    <b-row>
      You can use these placeholders, #total#, #channel_order_id#,
      #payment_method_title#, #shipping_carrier#, #tracking_number#,
    </b-row>
    <b-row>
      <b-col cols="3">
        <b-form-select
          :options="notificationMessages"
          text-field="title"
          value-field="id"
          v-model="selectedId"
        ></b-form-select>

        <b-row>
          <b-col cols="2">
            <b-button pill variant="primary" @click="saveNotificationMessage"
              >Save
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="7">
        <b-textarea
          rows="4"
          v-model="selectedNotification.message"
        ></b-textarea>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import ApiService from "../../../core/services/api.service";

export default {
  name: "Notifications",
  data() {
    return {
      selectedId: null,
      notificationMessages: []
    };
  },
  computed: {
    selectedNotification() {
      return this.notificationMessages.find(
        notification => notification.id === this.selectedId
      );
    }
  },
  components: {},
  methods: {
    saveNotificationMessage() {
      ApiService.put(
        `settings/notifications/${this.selectedId}`,
        this.selectedNotification
      )
        .then(({ data }) => {
          this.notificationMessages = data;
          this.makeToast("success", "Notification Saved Successfully!");
        })
        .catch(() => {
          this.makeToast("danger", "Error while saving notification");
        });
    },
    loadNotificationMessages() {
      ApiService.get("settings/notifications").then(({ data }) => {
        this.notificationMessages = data;
        this.selectedId = data[0].id;
      });
    },
    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: `Notifications`,
        variant: variant,
        solid: true
      });
    }
  },
  mounted() {
    this.loadNotificationMessages();
  }
};
</script>
